var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tag-list-wrap",class:{
    'multi-select': _vm.isMulti === 1,
    'sub-list': _vm.subList
  }},_vm._l((_vm.data),function(item,i){return _c('div',{key:i,staticClass:"item-area",class:[
      ("item-" + (item.id)),
      {
        'active': item.active,
        'inline': !item.show_textarea && _vm.isMulti !== 1,
      }
    ]},[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.itemClick(item, i)}}},[_vm._v(_vm._s(item.name))]),(item.show_textarea && item.active)?_c('div',{staticClass:"detail",on:{"click":function($event){$event.stopPropagation();return _vm.itemDetailClick.apply(null, arguments)}}},[_c('q-input',{staticClass:"textarea-style1",attrs:{"type":"textarea","placeholder":"내용을 입력해 주세요.","autogrow":""},on:{"input":function($event){return _vm.inputDetail(item.detail, i)},"blur":_vm.inputBlur},model:{value:(item.detail),callback:function ($$v) {_vm.$set(item, "detail", $$v)},expression:"item.detail"}})],1):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }